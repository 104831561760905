// settings

@use 'variables/colors' as colors;
@use 'variables/transition' as t;
@use 'partials/header' as header;

$progress_width: 100%;
$progress_height: .125rem;
$progress_bar_color: colors.$primary_emph;
$progress_bg: transparent;
$progress_border_radius: 0;

// directives

.etr-progress {
  position: fixed;
  top: header.$header_height;
  z-index: 201;
  transition: top t.$transition-duration linear 0s;
}

header.scrolled ~ .etr-main {
  .etr-progress {
    //top: calc(#{header.$header_height} - 1.5rem);
  }
}

.etr-progress[value] {
  appearance: none;
  // Get rid of default border in Firefox.
  border: 0 none;
  //border-bottom: 1px solid $white;
  width: $progress_width;
  height: $progress_height;
  // Firefox: any style applied here applies to the container.
  background-color: $progress_bg;
  border-radius: $progress_border_radius;
  // For IE10
  color: $progress_bar_color;
}

// For Firefox: progress bar
.etr-progress[value]::-moz-progress-bar {
  background-color: $progress_bg;
  border-radius: $progress_border_radius;
}

// WebKit/Blink browsers:
// -webkit-progress-bar is to style the container
.etr-progress[value]::-webkit-progress-bar {
  background-color: $progress_bg;
  border-radius: $progress_border_radius;
}

// -webkit-progress-value is to style the progress bar
.etr-progress[value]::-webkit-progress-value {
  background-color: $progress_bar_color;
  border-radius: $progress_border_radius;
}