// settings

@use '../variables/spacers' as spacers;
@use '../variables/colors' as colors;
@use '../variables/text_types' as *;
@use '../mixins/breakpoints' as bp;
@use '../mixins/elements' as elements;
@use '../helpers/text' as text;
@use '../layouts/wraps' as wraps;
@use '../partials/header' as header;

.etr-frontpage-main {
	.heading {
		@include elements.full-width-emphasized;
	}

	.heading-alt {
		@include elements.full-width-emphasized-alt;
	}

	.etr-frontpage-hero {
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			z-index: -1;
			background: colors.$back-other url('./assets/images/yellownet.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom;
			transform: translateY(20%) scaleY(-1);
			//opacity: 0.8;
		}

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -2;
			background-color: colors.$primary_bg;
		}

		header {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			flex: 1;
			align-items: flex-start;
			justify-content: center;
			padding-top: 5rem;
			padding-bottom: 3rem;

			@extend .etr-wrap--page;
			//@include elements.hero-header;

			@include bp.min(md) {
				padding-bottom: 5rem;
			}
		}

		// on super large screens, we want hero image to take 80% and the rest 20% will be covered by the your it partner
		@include bp.min(xl) {
			min-height: 80vh;
			// header {
			// 	//height: 50vh;
			// 	padding-top: 20vh;
			// 	padding-bottom: 20vh;
			// }
		}

		.hero-title {
			margin: -1rem 0 0rem;
			text-transform: uppercase;
			line-height: inherit;
			color: colors.$white;
			text-shadow: 0 0 3rem colors.$back-other;
			font-size: $h1-font-size-sm;

			@include bp.min(md) {
				margin: 0;
				font-size: $h1-font-size;
			}

			@include bp.min(lg) {
				margin: -1rem 0 0rem;
				font-size: $h1-font-size-lg;
			}

			@include bp.min(xl) {
				margin: -1rem 0 10rem;
			}

			.subtitle {
				width: fit-content;
				display: block;
				margin-top: 0.8rem;
				padding-left: 0;
				background-color: rgba(colors.$back-other, 0.5);
				font-size: $p-font-size;

				@include bp.min(md) {
					font-size: $title-large-font-size-xs;
				}

				@include bp.min(lg) {
					padding-left: 1rem;
				}
			}
		}

		.etr-button {
			margin-top: 5rem;
		}
	}

	.your-it-partner {
		border-top: 0.1rem solid colors.$yellow_dark;

		//padding-top: (spacers.$spacer / 2);
		//@include bp.min(md) { padding-top: 0; }
		//@include bp.min(lg) { padding-bottom: 0.25rem; }

		// for xl screens, our hero takes 80%, and this should take the rest 20%
		// min- is to avoid weirdness when we have odd wide but short screens
		@include bp.min(xl) {
			min-height: 20vh;
		}

		// align the text to the center
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	// an attempt in padding appropriately for big screens - but rather we stick to standard width
	//.how-we-help {
	//@include bp.min(xl) { > div {padding: 0 10%;} }
	//@include bp.min(1700px) { > div {padding: 0 20%;} }
	//}

	.how-we-help {
		p {
			max-width: 100%;
			margin-top: 1rem;
			margin-bottom: 0;
			line-height: 1.4;
		}

		.attack-business-challenges-wrap {
			min-height: 30rem;
			.content {
				min-height: 30rem;
				color: colors.$white;
				@include wraps.etr-wrap-page; // width as the rest of the things
			}
		}
		.tell-computers-wrap {
			display: flex;
			justify-content: space-around; // "center" would be fallback here
			.brain {
				flex-grow: 1;
				min-width: 15rem;
				text-align: center;

				img {
					padding: 0 3rem;
					position: relative;
					//top: -5rem;
					top: -18%;
				}
			}
			.content {
				flex-grow: 2;
				z-index: 2;
				@include wraps.etr-wrap-page;

				p {
					color: colors.$secondary_emph;
				}
			}

			// different display for super small screens (show brain behind text)
			@include bp.max(sm) {
				display: block;
				position: relative;
				.brain {
					position: absolute;
					width: 100%;
					margin-top: -15%;
					min-width: auto;

					img {
						max-height: 25rem;
					}
					text-align: center;
					z-index: 1;
				}
				.content {
					// overlay the background to make sure it looks readable. We need relative background to correctly z-index
					background: rgba(colors.$secondary_bg, 0.7);
					position: relative;
					height: 25rem;
				}
			}
		}
	}
	.services {
		//@include pad_big_screens;
		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			position: fixed;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			z-index: -3;
			background-image: url('./assets/images/hero-net.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			//background-attachment: fixed;
			transform: rotate(180deg);
			opacity: 0.7;
		}

		.heading {
			@include elements.full-width-emphasized;
			// handle the overlay case with the brain
			z-index: 2;
			position: relative;
			background: transparent;

			&.services-heading {
				padding-bottom: 1.5rem;
			}
		}
	}
	.services-listing {
		@include wraps.etr-wrap-page;
		grid-row-gap: 1rem;
		margin-bottom: spacers.$spacer;

		.services-item {
			position: relative;

			header {
				@include elements.vertical-align-center;
				min-height: 17rem;
				padding: 3rem 0 2rem;
				background-color: colors.$white;
				border-radius: 1.5rem;
				text-align: center;
				cursor: pointer;
				position: relative;
			}

			.service-icon {
				// Adjust filter over black icon to get primary emphasize color.
				// filter tool: https://codepen.io/sosuke/pen/Pjoqqp
				filter: invert(69%) sepia(62%) saturate(409%) hue-rotate(0deg)
					brightness(90%) contrast(97%);
			}

			.service-title {
				flex: 1;
				margin-bottom: 2rem;
				display: block;
				max-width: 80%;
				font-size: $h5-font-size-sm;
				line-height: inherit;
				color: colors.$secondary_emph;
			}

			.toggle-plus-icon {
				position: absolute;
				bottom: 1.5rem;
			}

			.description {
				display: none;
				min-height: 18rem;
				background-color: rgba(colors.$white, 0.9);
				border-radius: 1.5rem;
				padding: spacers.$spacer spacers.$spacer-sm;
				margin: spacers.$spacer-sm 0;
				position: relative;
				overflow: hidden;
				z-index: 1;

				@include bp.min(lg) {
					overflow: visible;
					padding-right: 24rem;
				}

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					height: 18rem;
					top: 0;
					right: 0;
					background-image: url('./assets/images/ai_hand_brain_2.png');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: right;
					z-index: -1;
					opacity: 0.2;

					@include bp.min(lg) {
						width: 24rem;
						z-index: 0;
						opacity: 1;
					}
				}

				.service-title {
					max-width: 100%;
					margin: 0;
					font-size: $h3-font-size-sm;
					text-align: center;
				}

				p {
					max-width: 100%;
					margin: 0.5rem 0;
					font-weight: 300;
					color: colors.$secondary_emph;
				}
			}

			&:hover,
			&.active {
				header {
					background-color: colors.$primary_emph;
				}
				.service-icon {
					filter: none;
				}

				.toggle-plus-icon {
					&:before {
						background-color: colors.$secondary_emph;
					}
					&:after {
						background-color: colors.$secondary_emph;
					}
				}
			}

			&.active {
				.description {
					display: block;
					animation: fadeInFromNone 0.75s ease-out;
				}
			}
		}
	}

	.clients {
		padding-bottom: spacers.$spacer-sm;

		&,
		&-heading {
			//background: transparent;
			background: colors.$secondary_bg;
			color: colors.$secondary_emph;
		}
	}
	.clients-list {
		display: flex;
		justify-content: space-evenly;
		flex-flow: row wrap;
		//@include wraps.etr-wrap-page;

		align-items: center;

		.client-logo {
			//flex-grow: 1;
			min-width: 20rem;
			height: 6.5rem;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: spacers.$spacer-sm;

			img {
				filter: grayscale(1);
				width: 12rem;
				//height: 100%;
				transition: all 0.3s ease-out;
			}
			//opacity: 0.5;
			//list-style-type: none;

			&:hover {
				img {
					filter: grayscale(0);
				}
			}
		}
	}
}
