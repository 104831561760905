// settings

@use '../variables/spacers' as s;
@use '../mixins/breakpoints' as bp;

// directives

.etr-related-posts {
  .etr-item {
    //padding-top: 0;
    border-bottom: 0 none;
  }
}
.etr-related-posts-header {
  padding-bottom: s.$spacer-sm;
}
.etr-related-posts--sidebar {
  //padding-top: 3.1rem;
  @include bp.max(md) {
    display: none;
  }
}