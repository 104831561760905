@use './colors' as colors;

// this file defines a mixin for each of the font sizes that we're using throughout the site
// Wherever we style text we should rely on this one

$xs-font-size: 0.75rem;
$small-font-size: 0.875rem;

$p-font-size: 1.15rem; // ~21px
$p-font-size-lg: 2rem;
$p-font-size-sm: 1rem;

$title-large-font-size: 5rem; // ~88.25px
$title-large-font-size-sm: 2.8rem;
$title-large-font-size-xs: 2rem;

$h1-font-size: 3.71rem; // ~65.4px
$h1-font-size-lg: 6rem;
$h1-font-size-sm: 2.409rem;

$h2-font-size: 2.9rem; //
$h2-font-size-sm: 2rem;

$h3-font-size: 2.29rem; // ~40.4px
$h3-font-size-sm: 1.704rem;

$h4-font-size: 1.91rem; // ~33.7px
$h4-font-size-sm: 1.414rem;

$h5-font-size: 1.52rem; //
$h5-font-size-sm: 1.205rem;

$h6-font-size: $p-font-size; // ~20.8px
$h6-font-size-sm: 1rem;

$heading-font-weight: 500;
$heading-margin-top: 1.75rem;
$heading-margin-top-sm: 1rem;

$list-style-type-size: 8px;

$small_titles_color: colors.$primary_fore;
