@use '../variables/colors' as colors;

.bg {
	&-primary {
		background-color: colors.$primary_bg;
	}

	&-secondary {
		background-color: colors.$secondary_bg;
	}

	&-tretary {
		background-color: colors.$tertiary_bg;
	}

	&-transparent {
		background-color: transparent;
	}
}
