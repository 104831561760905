// settings

@use '../variables/spacers' as s;
@use '../mixins/breakpoints' as bp;

$page-wrap: 94rem;
$total-width: $page-wrap + (s.$spacer * 2);
$total-width-xl: $page-wrap + (s.$spacer * 2.25);
//$total-padding-sm: $spacer-sm * 2;

@mixin wrap-padding {
  padding: 0 s.$spacer-sm;
  @include bp.min(md) {
    padding: 0 s.$spacer;
  }
  @include bp.min(lg) {
   //padding: 0 (s.$spacer);
  }
  @include bp.min(xl) {
    padding: 0 (s.$spacer * 1.75);
  }
  @include bp.range($min: $total-width) {
    padding: 0 (s.$spacer * 2.25);
  }
}

@mixin etr-wrap-page {
  @include wrap-padding;
  max-width: $page-wrap;
  margin: 0 auto;
}

//
.etr-wrap--page {
  @include etr-wrap-page;
  //&.etr-wrap--page--alt { padding: 0; }
}