// settings

@use '../variables/colors' as colors;
@use '../variables/spacers' as spacers;
@use '../mixins/breakpoints' as bp;
@use '../mixins/elements' as elements;
@use '../layouts/wraps' as wraps;
@use '../partials/header' as header;

.etr-services-main {
	.etr-services-hero {
		@include elements.hero-wrapper-with-net;
		.title {
			background: linear-gradient(
				to right,
				transparent 0%,
				rgba(colors.$primary_bg, 0.6) 5%,
				rgba(colors.$primary_bg, 0.6) 95%,
				transparent 100%
			);
		}
	}

	.services-listing {
		display: flex;
		flex-direction: column;
	}

	.services-item {
		background: colors.$secondary_bg;
		padding-top: spacers.$spacer;

		.inner-wrap {
			max-width: wraps.$page-wrap;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}

		.service-image {
			width: 100%;
			height: 25rem;
		}

		.description {
			padding: 0 spacers.$spacer_sm;
		}

		.service-title {
			color: colors.$primary_bg;
			text-transform: uppercase;
		}

		p {
			margin-top: 0;
			color: colors.$primary_bg;
			font-weight: 300;
		}

		&:nth-child(3n + 2) {
			background: colors.$tertiary_bg;
			p,
			.service-title {
				color: colors.$white;
			}
		}
		&:nth-child(3n + 3) {
			background: colors.$primary_bg;
			p,
			.service-title {
				color: colors.$white;
			}
		}

		@include bp.min(lg) {
			.inner-wrap {
				flex-direction: row;

				.service-image {
					width: 30rem;
					height: 30rem;
					align-self: center;
				}

				.service-title {
					margin-top: 0;
				}

				.description {
					max-width: 50%;
					padding: 0 spacers.$spacer_sm;
				}
			}

			&.odd-item {
				.service-image {
					order: 2;
				}

				.description {
					order: 1;
				}
			}
		}

		@include bp.min(xl) {
			.inner-wrap {
				.service-image {
					width: 40rem;
					height: 40rem;
				}
			}
		}
	}
}
