@use '../variables/colors' as colors;
@use '../layouts/wraps' as wraps;
@use '../mixins/elements' as elements;
@use '../base/typography' as ttype;

.etr-404-main {
  //padding: 3.8rem 0;
    .hero {
      @include elements.hero-wrapper-with-net;
  }
  .content {
    @include wraps.etr-wrap-page;
  }
}
