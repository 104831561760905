// settings

@use '../variables/spacers' as s;
@use '../variables/colors' as c;
// @use '../type/general' as gtype;
@use '../mixins/breakpoints' as bp;

%grid {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1rem; //(s.$spacer / 2);
  @include bp.min(md) {
    grid-column-gap: (s.$spacer-sm * 2);
  }
  @include bp.min(lg) {
    grid-column-gap: s.$spacer;
    grid-row-gap: 2rem;
  }
  @include bp.min(xl) {
    //grid-column-gap: (s.$spacer * 2);
  }
}

// grid based on min width grid items

.grid, .etr-grid {
  @extend %grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  //grid-auto-flow: column;
}

[class*="grid-item"], [class*="etr-grid-item"] {
  grid-column-start: span 1;
  //align-self: stretch;
  //justify-self: stretch;
}

@for $i from 1 through 12 {
  .grid-item--#{$i}, .etr-grid-item--#{$i} {
    grid-column-start: span #{$i};
  }
}

.grid-item-align--center, .etr-grid-item-align--center {
  align-self: center;
}
.grid-item-align--end, .etr-grid-item-align--end {
  align-self: end;
}

// grid based on grid items with "percentage" width

.grid--columns, .etr-grid-columns, .etr-grid--columns {
  @extend %grid;
  grid-template-columns: repeat(12, [col-start] minmax(1px, 1fr) [col-end]);
}
.etr-grid--columns--2 {
  @extend %grid;
  grid-template-columns: repeat(2, [col-start] minmax(1px, 1fr) [col-end]);
}
.etr-grid--columns--3 {
  @extend %grid;
  grid-template-columns: repeat(3, [col-start] minmax(1px, 1fr) [col-end]);
}

[class*="grid-column"], [class*="etr-grid-column"] {
  grid-column-start: span 12;
  //align-self: stretch;
  //justify-self: stretch;
}

@for $i from 1 through 12 {
  .grid-column--#{$i}, .etr-grid-column--#{$i} {
    grid-column-start: span #{$i};
  }
}

@each $s in (sm, md, lg, xl) {
  @for $i from 1 through 12 {
    .grid-column--#{$s}--#{$i}, .etr-grid-column--#{$s}--#{$i} {
      @include bp.min(#{$s}) {
        grid-column-start: span #{$i};
      }
    }
  }
}

// shared

// Gap
.etr-grid-gap--sm, .etr-grid-column-gap--sm {
  @include bp.min(lg) {
    grid-column-gap: 1rem;
  }
}
.etr-grid-gap--lg, .etr-grid-column-gap--lg {
  @include bp.min(lg) {
    grid-column-gap: 4.15rem;
  }
}
.etr-grid-row-gap--none {
  grid-row-gap: 0;
}
.etr-grid-column-gap--none {
  grid-column-gap: 0;
}
