// settings

@use '../variables/colors' as colors;
@use '../variables/spacers' as s;
@use '../variables/transition' as transition;
// @use '../variables/box-shadow' as b;
@use '../mixins/breakpoints' as bp;
@use '../layouts/wraps' as wraps;
@use '../base/typography' as ttypes;

//$header-height: 5rem;
$header-height: 3.5rem;
//$header-height-scrolled: 3.5rem;
$header-item-margin-sm: 0.25rem 0;

// directives

body {
	// might want to pad body in order to allow for the header
	// however, this creates a weird effect on a full color bg
	// padding-top: $header-height;
}

.etr-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: $header-height;

	// When we load the header it's transparent. But as we scroll down, we transition to full color
	// background with some transparency
	// An alternative to this is changing the height
	background-color: transparent;
	transition: background-color transition.$transition-duration linear 0s;

	// Alternative
	//transition: height transition.$transition-duration linear 0s;

	z-index: 111;
	&--scrolled {
		background-color: rgba(colors.$primary_bg, 0.8);
		// If we want to do the height transition, we can set our height to this
		//height: calc(#{$header-height} - 1.5rem);

		.etr-header-nav-list {
			top: calc(#{$header-height} - 1.54rem);
		}
	}
}

.etr-header-nav {
	// regulate max width
	@include wraps.etr-wrap-page;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%; // take up all the height so that our center align-items works
	// padding-top: 1.315rem;
	// padding-bottom: 1.315rem;
}
.etr-header-logo {
	grid-column: 1 / span 3;
}
.etr-header-logo-image {
	width: 6rem;
	@include bp.min(lg) {
		width: 6.75rem;
	}
}
// right hand section - links on menu
.etr-header-links {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	top: $header_height;
	right: -105vw;
	height: calc(100vh - #{$header_height});
	transition: all transition.$transition-duration ease-out 0s;
	@include bp.min(lg) {
		position: initial;
		height: auto;
		flex-direction: row;
		align-items: center;
	}
	&.is-shown {
		left: -(s.$spacer-sm);
		right: -(s.$spacer-sm);
		padding-right: 1.75rem;
		background-color: rgba(colors.$primary_bg, 0.975);
		@include bp.min(md) {
			left: -(s.$spacer);
			right: -(s.$spacer);
			padding-right: s.$spacer + 0.5;
		}
	}
}

%etr-header-nav-link--active {
	color: colors.$primary_emph;
	&:hover {
		color: colors.$primary_highlight;
	}
}

// (ul) container for the menu items
//.etr-header.scrolled {
//  .etr-header-links {
//    top: $header-height-scrolled;
//  }
//}
//
.etr-header-nav-list {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0;
	list-style-type: none;

	@include bp.min(lg) {
		flex-direction: row;
		align-items: center;
	}
	.menu-item {
		margin: $header-item-margin-sm;
		padding-left: 0;
		&::before {
			content: initial;
		}
		&.current-menu-item {
			.etr-header-nav-link {
				@extend %etr-header-nav-link--active;
			}
		}
		@include bp.min(lg) {
			display: flex;
			align-items: center;
			margin: 0;
			margin-left: 2rem;
		}
	}
}
.etr-header-nav-link {
	position: relative;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-weight: 400;
	font-size: ttypes.$small-font-size;
	line-height: 1;
	text-decoration: none;
	color: colors.$primary_fore;
	@include bp.min(lg) {
		padding-top: 0.15rem;
		padding-bottom: 0.15rem;
	}
	&:hover {
		color: colors.$white;
		//font-weight: 600;
	}
	&.is-active {
		@extend %etr-header-nav-link--active;
	}
	//transition: border-bottom .25s ease-out 0s, color .25s ease-out 0s;
	// &::before {
	//   content: '';
	//   position: absolute;
	//   bottom: -2px;
	//   left: 0;
	//   width: 0%;
	//   height: 2px;
	//   background-color: $blue;
	//   animation-duration: .2s;
	//   animation-timing-function: ease-out;
	//   animation-fill-mode: forwards;
	// }
	// &:hover {
	//   text-decoration: none;
	//   color: $blue;
	//   // color: $blue;
	//   &::before {
	//     //animation-name: animateBottomBorder;
	//   }
	// }
}

// hamburger
.etr-hamburger.etr-header-hamburger {
	//padding: 0;
	@include bp.min(lg) {
		display: none;
	}
}

// cta
.etr-header-cta {
	@include bp.max(md) {
		margin: $header-item-margin-sm;
	}
	.etr-button {
		margin-left: 3rem;
		@include bp.max(md) {
			//display: none;
			height: auto;
			min-width: initial;
			margin: 0;

			//line-height: 1.8;
			&:active {
				box-shadow: none;
			}
			&:hover {
				//text-decoration: underline;
			}
		}
	}
}

//$arrow-icon: url('data:image/svg+xml;charset=utf8,<svg width="661" height="170" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#1C448E" d="M0 65h640v40H0z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M632.421 56.569L575.853 0l-28.284 28.284 56.568 56.569-56.568 56.568 28.284 28.285 56.568-56.569 28.285-28.284-28.285-28.284z" fill="#1C448E"/></svg>');
.etr-hamburger {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	height: 0.6rem;
	background-color: transparent;
	border: 0 none;
	&:focus {
		outline: none;
	}
	&.is-active {
		//padding-top: .2rem;
		width: 32px;
		height: 32px;
		padding: 0;
		margin: 1px 6px; // todo fix with reseting padding for buttons
		.etr-hamburger-inner {
			&.one {
				display: block;
				width: 100%;
				height: 100%;
				background-image: url('./assets/images/arrow_right.svg');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 32px;
				background-color: transparent;
			}
			&.two {
				display: none;
			}
		}
	}
	&:hover {
		.etr-hamburger-inner {
			&.two {
				width: 32px;
			}
		}
	}
}
.etr-hamburger-inner {
	// width: 2rem;
	width: 32px;
	// height: .1rem;
	height: 2px;
	background-color: colors.$primary_fore;
	&.two {
		width: 20px;
		transition: width 0.15s ease-in 0s;
	}
}
