@use '../variables/colors' as colors;

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeOutFromNone {
	0% {
		display: block;
		opacity: 1;
	}
	1% {
		display: block;
		opacity: 1;
	}

	100% {
		display: none;
		opacity: 0;
	}
}

@keyframes emphError {
	0% {
		color: auto;
	}
	50% {
		color: colors.$error;
	}

	100% {
		color: auto;
	}
}
