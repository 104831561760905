/* Inspired by https://github.com/tobiasahlin/SpinKit */

// settings

@use 'variables/colors' as c;

// directives

.etr-loader {
  //position: fixed;
  top: 25vh;
  left: calc(50vw - 2rem);
  display: flex;
  justify-content: space-between;
  margin: 100px auto 0;
  width: 4rem;
  text-align: center;
  z-index: 10001;
  &.fixed {
    position: fixed;
  }
}
.etr-loader > div {
  width: 1rem;
  height: 1rem;
  background-color: c.$primary_fore;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.etr-loader .bounce1 {
  animation-delay: -0.32s;
}
.etr-loader .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}