// settings

@use '../variables/spacers' as s;
@use '../variables/colors' as colors;
@use '../mixins/breakpoints' as bp;
@use '../layouts/wraps' as wraps;
@use '../base/general' as type;
@use '../partials/header' as header;

//
.single-post {
  background-image: none;

  padding-top: header.$header-height;
  background-color: colors.$primary_bg;
}
//
.etr-entry-main {
  padding-top: 0;

  &, p, .etr-entry-readingTime small {
    //color: c.$back-soft;
  }
  a {
    //color: c.$fore-main;
    //&:hover {color: c.$fore-other;}
  }
}
.etr-entry-header {
  padding-top: (s.$spacer * 4);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @include bp.min(lg) {
    padding-top: (s.$spacer * 6);
  }
  @include bp.min(xl) {
    padding: (s.$spacer * 6) (s.$spacer * 1.75) 0 0;
  }
  @include bp.range($min: wraps.$total-width) {
    padding-right: calc((100vw - (#{wraps.$page-wrap} - #{s.$spacer * 2.125 * 2})) / 2);
  }
}
.etr-entry-title {
  //@include h2;
}
.etr-entry-header-inner {
  //margin-top: 2px;
  padding: s.$spacer-sm;
  //padding-left: s.$spacer-sm;
  //background-color: rgba(c.$white, 1);

  $semitransparent_color: rgba(colors.$primary_bg, 0.7);

  background: linear-gradient(to top, colors.$primary_bg 0%, $semitransparent_color 30%, $semitransparent_color 80%, transparent 100%);
  h1 {color: colors.$primary_fore;}


  @include bp.min(md) {
    padding-left: s.$spacer;
  }
  @include bp.min(lg) {
    padding-top: s.$spacer;
  }
  @include bp.min(xl) {
    padding: s.$spacer;
    padding-left: (s.$spacer * 1.75);
  }
  @include bp.range($min: wraps.$total-width-xl) {
    padding-left: calc((100vw - (#{wraps.$page-wrap} - #{s.$spacer * 2.125 * 2})) / 2);
  }
}
.etr-entry-inner {
  padding-bottom: (s.$spacer-sm * 2);
  @include bp.min(lg) {
    //padding-top: s.$spacer;
  }

}
.etr-entry-content {
  padding-top: s.$spacer-sm;
  padding-bottom: s.$spacer-sm;
  @include bp.min(lg) {
    //padding-top: s.$spacer-sm;
  }
  @include bp.min(xl) {
    //padding-left: (s.$spacer * 3);
  }
  @include bp.range($min: wraps.$page-wrap) {
    //padding-left: (s.$spacer * 2);
  }
}
.etr-entry-meta {
  //margin-bottom: .25rem;
}
.etr-entry-categories {
  margin-right: 1rem;
  //a {
  //  color: c.$primary_emph;
  //  &:hover {
  //    color: c.$primary_highlight;
  //  }
  //}
}
.etr-entry-readingTime {
  white-space: nowrap;
  &.page {
    //display: none;
  }
  small {
    //color: c.$primary_fore;
  }
}
.etr-entry-related-posts {
  padding: (s.$spacer-sm * 1.5) 0 s.$spacer-sm 0;
  @include bp.min(lg) {
    padding: s.$spacer 0;
  }
}