/*
Theme Name: Etrellium
*/

@use 'icons/foundation';
@use 'icons/etr-icons';

@use 'base/normalize';
@use 'base/general';
@use 'base/typography' as typegeneral;

@use 'layouts/wraps';
@use 'layouts/grid';
@use 'layouts/flex';
@use 'layouts/general' as layoutsgeneral;

@use 'components/forms/consultation';
@use 'components/forms/general' as formsgeneral;

@use 'components/modal/general' as modalgeneral;
@use 'components/modal/page';

@use 'components/breadcrumbs';
@use 'components/buttons';
@use 'components/calendly';
@use 'components/cookies';
@use 'components/loader';
@use 'components/progress-bar';
@use 'components/search';
@use 'components/toggle';

@use 'partials/cta/contact' as ctacontact;
@use 'partials/card';
@use 'partials/content-nav';
@use 'partials/footer';
@use 'partials/header';
@use 'partials/item';
@use 'partials/nav';
@use 'partials/pagination';
@use 'partials/related-posts';
@use 'partials/sidebar';
@use 'partials/social-share';

@use 'pages/frontpage';
@use 'pages/missing';
@use 'pages/services';
@use 'pages/about';
@use 'pages/blog';
@use 'pages/contact';
@use 'pages/archive';
@use 'pages/entry';

//@use 'helpers/general' as helpersgeneral;
@use 'helpers/backgrounds';
@use 'helpers/animations';
//@use 'helpers/text';
//@use 'helpers/sizes';
//@use 'helpers/spacers';
