// settings

@use 'variables/colors' as colors;
@use 'variables/text_types' as *;
@use 'mixins/breakpoints' as bp;
//@use 'partials/header' as header;

// directive

html {
	font-family: 'Inter', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	color: colors.$primary_fore;
	@include bp.min(md) {
		font-size: 1.1rem;
	}
}

body {
	background-color: colors.$back-other;
}

main {
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

figure {
	margin: 0;
}

* {
	box-sizing: border-box;
}

.etr-page--default {
	//background-image: url('./assets/images/hero-bg-2_alt.svg');
	//background-size: 200%;
	//background-position: top 0 right 25%;
	//background-repeat: no-repeat;
	//background-attachment: fixed;
	//background-color: rgba($gray_1, 1);
	@include bp.min(sm) {
		// background-position: top 0 right 25%;
	}
	@include bp.min(md) {
		background-size: 150%;
		// background-position: top 0 right 25%;
	}
	@include bp.min(xl) {
		background-size: 100%;
		background-position: top -10vh right 50%;
		//background-color: rgba(colors.$gray-1, 1);
	}
}
.etr-page-section {
	padding-bottom: 1.55rem;
	@include bp.min(md) {
		padding-bottom: 3.1rem;
	}
	&.etr-page-section--lg {
		@include bp.min(md) {
			padding-bottom: 6.2rem;
		}
	}
}
.etr-page-content {
	padding: 3.1rem 0;
}

.etr-main {
	// Leave space for the menu - but not applicable any more since we start off with hero images
	//padding-top: 6.2rem;
	//padding-bottom: 3.8rem;
}

.etr-mobile-nav-open {
	overflow: hidden;
}

.screen-reader-text {
	border: 0;
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
	word-break: normal;
}

.hidden {
	display: none !important;
}
.invisible {
	visibility: hidden !important;
}

/* 
* Aspect Ratio Boxes 
*/
[style*='--aspect-ratio'] > :first-child {
	width: 100%;
}
[style*='--aspect-ratio'] > img {
	height: auto;
}
@supports (--custom: property) {
	[style*='--aspect-ratio'] {
		position: relative;
	}
	[style*='--aspect-ratio']::before {
		content: '';
		display: block;
		padding-bottom: calc(100% / (var(--aspect-ratio)));
	}
	[style*='--aspect-ratio'] > :first-child {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
}

.bg-image {
	width: 100%;
	height: auto;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
