// settings

@use '../../variables/colors' as colors;
@use '../../mixins/breakpoints' as bp;
@use '../../base/typography' as ttype;

$input-button-height-sm: 1.925rem;
$input-button-height: 2.425rem;
$input-button-height-lg: 3.425rem; // TODO

%label-when-has-text {
	top: -1.175rem;
	left: 0.62rem;
	padding: 0 0.62rem;
}

.etr-form {
	//padding: 3.8rem 0;
}
.etr-form-item {
	position: relative;
	padding-bottom: 2.3rem;
	&.etr-form-item--sm {
		padding-bottom: 1.15rem;
	}
	&.has-text {
		.etr-form-label {
			@extend %label-when-has-text;
		}
	}
}
.etr-form-label {
	position: absolute;
	top: 0.65rem;
	left: 1px;
	display: flex;
	align-items: center;
	height: 2rem;
	padding: 0 1.24rem;
	font-size: ttype.$small-font-size;
	color: colors.$primary_fore;
	background-color: colors.$secondary_bg;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.2s ease-in 0s;
	cursor: text;
}
.etr-form-field {
	box-sizing: border-box;
	width: 100%;
	height: $input-button-height;
	padding: 1.24rem;
	line-height: 1.8;
	border: 0 none;
	border-bottom: 1px solid colors.$secondary_bg;
	outline: 0 none;
	//z-index: 101;
	&:focus {
		border-bottom: 1px solid colors.$secondary_emph;
		& ~ .etr-form-label {
			@extend %label-when-has-text;
			//opacity: 1;
			//pointer-events: initial;
		}
	}
	&:placeholder {
		color: colors.$secondary_bg;
	}
	&.alt {
		background-color: transparent;
	}
	// size limits
	&.etr-form-field--md {
		max-width: 14rem;
	}
	&.etr-form-field--lg {
		max-width: 28rem;
	}
}

textarea.etr-form-field {
	//height: auto;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}

// checkbox

$icon-check: url('data:image/svg+xml;charset=utf8,%3Csvg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.60994 7.89L1.49994 4.78L0.439941 5.84L4.60994 10L13.5599 1.05L12.4999 0L4.60994 7.89Z" fill="%23fff" /%3E%3C/svg%3E');
$icon-check-readOnly: url('data:image/svg+xml;charset=utf8,%3Csvg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.60994 7.89L1.49994 4.78L0.439941 5.84L4.60994 10L13.5599 1.05L12.4999 0L4.60994 7.89Z" fill="%23878787" /%3E%3C/svg%3E');
$icon-close: url('data:image/svg+xml;charset=utf8,%3Csvg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M17.1663 2.4785L15.5213 0.833496L8.99968 7.35516L2.47801 0.833496L0.833008 2.4785L7.35468 9.00016L0.833008 15.5218L2.47801 17.1668L8.99968 10.6452L15.5213 17.1668L17.1663 15.5218L10.6447 9.00016L17.1663 2.4785Z" fill="white" /%3E%3C/svg%3E');
$icon-close-readOnly: url('data:image/svg+xml;charset=utf8,%3Csvg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M17.1663 2.4785L15.5213 0.833496L8.99968 7.35516L2.47801 0.833496L0.833008 2.4785L7.35468 9.00016L0.833008 15.5218L2.47801 17.1668L8.99968 10.6452L15.5213 17.1668L17.1663 15.5218L10.6447 9.00016L17.1663 2.4785Z" fill="%23878787" /%3E%3C/svg%3E');
$circle: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$size: 1.55rem;

.etr-CustomControl {
	position: relative;
	display: block;
	// padding-left: 32px;
	cursor: pointer;
	&.disabled {
		pointer-events: none;
	}
	// &.etr-CustomControl--button {
	//   .etr-CustomControl-label {
	//     @include button;
	//     @include button--lg;
	//     opacity: .6;
	//     &::before, &::after {
	//       display: none;
	//     }
	//     .etr-CustomControl-label-text {
	//       padding-left: 0;
	//     }
	//   }
	//   .etr-CustomControl-input:checked {
	//     & ~ .etr-CustomControl-label {
	//       opacity: 1;
	//     }
	//     &:hover {
	//       & ~ .etr-CustomControl-label {
	//         background-color: $secondary_emph;
	//       }
	//     }
	//   }
	// }
	&:hover {
		.etr-CustomControl-label {
			&::before {
				border-color: colors.$secondary_emph;
			}
		}
	}
}
.etr-CustomControl--noLabel {
	// padding-left: 20px;
	.etr-CustomControl-label {
		.etr-CustomControl-label-text {
			padding-left: 20px;
		}
	}
}
.etr-CustomControl-input {
	position: absolute;
	z-index: -1; // Put the input behind the label so it doesn't overlay text
	opacity: 0;
	&:checked ~ .etr-CustomControl-label::before {
		color: colors.$secondary_emph;
	}
	&:focus ~ .etr-CustomControl-label::before {
	}
	&:active ~ .etr-CustomControl-label::before {
		//color: $custom-control-indicator-active-color;
		//background-color: $custom-control-indicator-active-bg;
	}
	&:disabled {
		~ .etr-CustomControl-label {
			color: colors.$tertiary_emph;
			&::before {
				background-color: colors.$tertiary_fore;
			}
			.etr-CustomControl-label-text {
				color: colors.$tertiary_fore;
			}
		}
	}
}
.etr-CustomControl-label {
	position: relative;
	display: inline-block;
	margin-bottom: 0;
	padding-top: 2px;
	font-size: 1rem;
	// line-height: 25px;
	cursor: pointer;

	// Background (color)
	&::before {
		position: absolute;
		top: 1px;
		left: 0;
		display: block;
		width: $size;
		height: $size;
		pointer-events: none;
		content: '';
		user-select: none;
		background-color: colors.$tertiary_emph;
		border: 1px solid colors.$tertiary_fore;
		transition: all 0.15s linear 0s;
		@include bp.min(lg) {
			top: -1px;
		}
	}
	// Foreground (icon)
	&::after {
		position: absolute;
		top: 2px;
		left: 2px;
		display: block;
		width: $size;
		height: $size;
		content: '';
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 17px;
		@include bp.min(lg) {
			top: 0;
		}
	}
}
.etr-CustomControl-label-text {
	display: inline-block;
	padding-left: 2.7rem;
	line-height: 1.5;
	//overflow: hidden;
	//text-overflow: ellipsis;
	// white-space: nowrap;
}

// Custom Checkboxes

.etr-CustomControl--checkbox {
	.etr-CustomControl-input {
		&:checked ~ .etr-CustomControl-label {
			&::before {
				background-color: colors.$tertiary_bg;
				border-color: colors.$tertiary_bg;
			}
			&::after {
				background-image: $icon-check;
			}
		}
		&:checked {
			&[readonly] ~ .etr-CustomControl-label,
			&:disabled ~ .etr-CustomControl-label {
				cursor: default;
				&::before {
					background-color: colors.$secondary_bg;
				}
				&::after {
					background-image: $icon-check-readOnly;
				}
			}
		}
		&[readonly] ~ .etr-CustomControl-label {
			cursor: default;
			pointer-events: none;
			&::before {
				background-color: colors.$secondary_bg;
				border-color: darken(colors.$secondary_bg, 10%);
			}
			&::after {
				//background-image: $etr-SvgIcon--checkReadOnly;
				//color: $gray_8;
			}
			.etr-CustomControl-label-text {
				color: colors.$gray-7;
			}
		}
		&[disabled] ~ .etr-CustomControl-label {
			cursor: default;
			pointer-events: none;
			&::before {
				background-color: colors.$secondary_bg;
				border-color: darken(colors.$secondary_bg, 10%);
			}
			.etr-CustomControl-label-text {
				color: colors.$gray-7;
			}
		}
	}
	.etr-CustomControl-input:disabled {
		&:checked ~ .etr-CustomControl-label::before {
		}
		&:indeterminate ~ .etr-CustomControl-label::before {
		}
		& ~ .etr-CustomControl-label {
			&:before {
				border-color: darken(colors.$secondary_bg, 10%);
			}
			.etr-CustomControl-label-text {
				color: colors.$secondary_fore;
			}
		}
	}
	// checkbox with an x
	&.crossed {
		.etr-CustomControl-input {
			&:checked ~ .etr-CustomControl-label {
				&::after {
					background-image: $icon-close;
				}
			}
			&:checked {
				&[readonly] ~ .etr-CustomControl-label,
				&:disabled ~ .etr-CustomControl-label {
					&::after {
						background-image: $icon-close-readOnly;
					}
				}
			}
		}
		.etr-CustomControl-label {
			&::after {
				background-size: 14px;
			}
		}
	}
}

// Radios

.etr-CustomControl--radio {
	.etr-CustomControl-label {
		&::before {
		}
		&::after {
			top: 0;
			background-size: initial;
		}
	}
	.etr-CustomControl-input {
		& ~ .etr-CustomControl-label {
			&::before {
				border-radius: 50%;
			}
			&::after {
			}
		}
		&:checked ~ .etr-CustomControl-label {
			&::before {
				background-color: colors.$tertiary_bg;
			}
			&::after {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
			}
		}
		&:checked {
			&[readonly] ~ .etr-CustomControl-label,
			&:disabled ~ .etr-CustomControl-label {
				&:before {
					background-color: colors.$primary_fore;
				}
				&::after {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='darkgray'/%3e%3c/svg%3e");
				}
			}
			&:disabled ~ .etr-CustomControl-label {
				&:before {
					background-color: colors.$primary_fore;
				}
				&::after {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='darkgray'/%3e%3c/svg%3e");
				}
			}
		}

		&[readonly] ~ .etr-CustomControl-label {
			cursor: default;
			pointer-events: none;
			&::before {
				background-color: colors.$primary_fore;
				border-color: colors.$primary_emph;
			}
			&::after {
				//background-image: $etr-SvgIcon--checkReadOnly;
				//color: $gray_8;
			}
			.etr-CustomControl-label-text {
				color: colors.$black;
			}
		}
		&:disabled ~ .etr-CustomControl-label {
			cursor: default;
			pointer-events: none;
			&::before {
				background-color: colors.$primary_fore;
				border-color: colors.$primary_emph;
			}
			&::after {
				//background-image: $etr-SvgIcon--checkReadOnly;
				//color: $gray_8;
			}
			.etr-CustomControl-label-text {
				color: colors.$secondary_fore;
			}
		}
	}
	.etr-CustomControl-input:disabled {
		&:checked ~ .etr-CustomControl-label::before {
		}
	}
}
