// settings

@use '../variables/colors' as c;
@use '../mixins/breakpoints' as bp;

// directives

.etr-text--orange {
	color: c.$orange !important;
}
.etr-text--blue {
	color: c.$blue !important;
	&::selection {
		background-color: c.$blue_1;
	}
}
.etr-text--blue_1 {
	color: c.$blue_1 !important;
}
.etr-text--blue_2 {
	color: c.$blue_2 !important;
}
// .etr-text--blue_3 {
//   color: c.$blue_3 !important;
// }
.etr-text--blue_4 {
	color: c.$blue_4 !important;
}

//
.etr-text--black {
	color: c.$black;
}
.etr-text--white {
	color: c.$white;
}
.etr-text--primary {
	color: c.$primary_emph;
}
.etr-text--gray_1 {
	color: c.$gray_1;
}
.etr-text--gray_2 {
	color: c.$gray_2;
}
.etr-text--gray_3 {
	color: c.$gray_3;
}
.etr-text--gray_4 {
	color: c.$gray_4;
}
.etr-text--gray_5 {
	color: c.$gray_5;
}
.etr-text--gray_6 {
	color: c.$gray_6;
}
.etr-text--gray_7 {
	color: c.$gray_7;
}

[class*='etr-text--'] {
	&::selection {
		color: c.$white;
		background-color: c.$blue;
	}
}

//
.etr-text--center {
	text-align: center;
}
.etr-text--right {
	text-align: right;
}
.etr-text--left {
	text-align: left;
}

//
.etr-text--light {
	font-weight: 300 !important;
}
.etr-text--normal {
	font-weight: 400 !important;
}
.etr-text--medium {
	font-weight: 500 !important;
}
.etr-text--semiBold,
.etr-text--semi-bold {
	font-weight: 600 !important;
}
.etr-text--bold {
	font-weight: 700 !important;
}
.etr-text--extraBold,
.etr-text--extra-bold {
	font-weight: 800 !important;
}

//
.etr-text--uppercase {
	text-transform: uppercase;
}

//
@keyframes typing-text-cursor {
	from,
	to {
		border-right-color: transparent;
	}
	50% {
		border-right-color: c.$black;
	}
}
@keyframes typing-text-bottom-cursor {
	from,
	to {
		border-bottom-color: transparent;
	}
	50% {
		border-bottom-color: c.$black;
	}
}

.etr-text--typing {
	padding-right: 1rem;
	border-right: 2px solid c.$black;
	animation-name: typing-text-cursor; //1s steps(29,end) 1s 1 normal both infinite;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: step-end;
	//animation-delay: .9s;
}

.etr-text-suffix--typing {
	display: inline-block;
	width: 1.25rem;
	margin-left: 0.5rem;
	margin-bottom: -0.3rem;
	border-bottom: 0.3rem solid c.$black;
	animation-name: typing-text-bottom-cursor; //1s steps(29,end) 1s 1 normal both infinite;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: step-end;
	@include bp.min(lg) {
		width: 2rem;
		margin-left: 1rem;
		margin-bottom: -0.5rem;
		border-bottom: 0.5rem solid c.$black;
	}
}
