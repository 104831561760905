// settings

@use '../variables/spacers' as s;
@use '../mixins/breakpoints' as bp;

// directives

.etr-page--contact {
  .etr-page-footer {
    display: none;
  }
}
//
.etr-contact-main {
  padding: 0 0 (s.$spacer / 3) 0;
  @include bp.min(md) {
    padding: 0 0 s.$spacer 0;
  }
}