// settings

@use '../variables/spacers' as s;
@use '../variables/colors' as c;
@use '../mixins/breakpoints' as bp;
@use '../layouts/wraps' as wraps;
@use '../partials/header' as header;
@use '../base/typography' as ttype;

// directives

.etr-sidebar {

  &.etr-sidebar--entry {
    //padding-top: 3.8rem;
    &.no-top-margin {
      //margin-top: 0;
    }
  }
  &.etr-sidebar--left {
    // position: absolute;
    // top: 50%;
    //padding-top: $spacer * 2;
    // transform: translateY(-50%);
    display: none;
    align-items: center;
    &.fixed {
      //display: none;
      position: fixed;
      top: 10vh;
      left: -14rem;
      
      height: 62vh;
      max-width: 7.25rem;
      //width: 7.25rem;
      height: 80vh;
      //transform: translateY(-50%);
      transition: left .15s linear 0s;
      //background-color: $gray-2;
      @include bp.min(lg) {
        max-width: 11.5rem;
        top: 7.5rem;
      }
      @include bp.min(1850px) {
        display: flex;
      }
    }
    &.show {
      //display: block;
      left: s.$spacer-sm;
      @include bp.min(lg) {
        left: 1.5rem;
      }
      @include bp.range($min: 100rem) {
        left: 2rem;
      }
      @include bp.min(xl) {
        //left: 5rem;
      }
    }
  }
  &.fixed {
    height: 100%;
    //margin-top: 6.2rem;
    .etr-sidebar--right-inner {
      &.is-fixed {
        @include bp.min(lg) {
          position: fixed;
          top: calc(#{header.$header-height} - 1.5rem);
          width: calc((100% / 12 * 4) - #{s.$spacer * 1.75}); // 16.2rem;
        }
        @include bp.range($min: wraps.$total-width-xl) {
          width: calc((#{wraps.$page-wrap} / 12 * 4) - #{s.$spacer * 2}); // 16.2rem;
        }
        @include bp.range($min: 'sm', $max: 'xl') {
          width: calc((100% / 12 * 4) - #{s.$spacer * 2}); // 16.2rem;
          color: red;
        }
      }
    }
    &.relative {
      @include bp.min(lg) {
        position: relative;
        //margin-top: 0;
      }
      .etr-sidebar--right-inner {
        &.is-fixed {
          @include bp.min(lg) {
            position: absolute;
            top: initial;
            bottom: 0;
          }
        }
      }
    }
  }
  .etr-sidebar--right-inner {
    //background-color: rgba(c.$gray-2, .85);
    background-color: c.$back-soft;
    //color: c.$back-other;
    //a {color: c.$fore-main;}
    a, h6, & {color: c.$back-other;}
    a:hover {color: c.$back-main;}

    @include bp.min(lg) {
      //margin-top: 6.2rem;
      //margin: 1.55rem 0;
      //padding-top: 1.55rem;
      //padding-bottom: 1.55rem;
    }
    ul {
      padding-left: 0;
      li {
        padding-left: 0;
        margin-bottom: .75rem;
        &::before {
          display: none;
        }
        a {
          font-size: ttype.$small-font-size;
          font-weight: 600;
        }
      }
    }
    h6 {
      margin-bottom: 1rem;
    }
  }
  .etr-sidebar--right-bg {
    // top and bottom padding nto necessary as subelements will provide their own
    padding: 0.1rem s.$spacer-sm;
    //background-color: rgba(c.$white, .85);


    @include bp.min(lg) {
      //padding-top: (s.$spacer-sm * 2);
      padding-left: s.$spacer-sm;
    }
    @include bp.min(xl) {
      //padding-left: s.$spacer;
    }
    @include bp.range($min: wraps.$total-width-xl) {
      //padding-left: s.$spacer;
    }
  }
}