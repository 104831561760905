// settings

@use '../variables/colors' as c;
@use '../variables/spacers' as s;
@use '../base/bootstrap' as boot;
@use '../base/typography' as ttype;
@use '../mixins/breakpoints' as bp;

/* Nav inspired by Bootstrap v4.5.2 (https://getbootstrap.com/) */

// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: (s.$spacer-sm / 2) 0;
  font-size: ttype.$small-font-size;
  //text-decoration: if($link-decoration == none, null, none);

  // @include hover-focus() {
  //   text-decoration: none;
  // }
  @include bp.min(md) {
    padding: boot.$nav-link-padding-y boot.$nav-link-padding-x;
  }

  // Disabled state lightens text
  &.disabled {
    color: boot.$nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {
  border-bottom: boot.$nav-tabs-border-width solid boot.$nav-tabs-border-color;

  .nav-item {
    margin-bottom: -2px; //-$nav-tabs-border-width;
    padding-left: 0;
    &:first-child {
      // padding-left: 0;
    }
  }

  .nav-link {
    // border: $nav-tabs-border-width solid transparent;
    border-bottom: 3px solid transparent;
    text-align: center;
    //@include border-top-radius($nav-tabs-border-radius);

    // @include hover-focus() {
    //   border-color: $nav-tabs-link-hover-border-color;
    // }
    &:hover {
      color: c.$blue;
      border-bottom-color: c.$gray-3;
    }
    &:focus {
      outline: none;
    }
    &.disabled {
      color: boot.$nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: boot.$nav-tabs-link-active-color;
    background-color: boot.$nav-tabs-link-active-bg;
    border-color: boot.$nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -(boot.$nav-tabs-border-width);
    // Remove the top rounded corners here since there is a hard edge above the menu
    //@include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  .nav-link {
    //@include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: boot.$nav-pills-link-active-color;
    //background-color: $nav-pills-link-active-bg;
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

// etr mods

.nav-item {
  &::before {
    display: none;
  }
}
