// settings

@use '../variables/spacers' as spacers;
@use '../variables/colors' as colors;
@use '../variables/text_types' as tt;
@use '../base/typography' as ttype;
@use '../mixins/breakpoints' as bp;
@use '../mixins/elements' as elements;
@use '../layouts/wraps' as wraps;
@use '../partials/header' as header;

//
.etr-page--blog {
	.etr-header.scrolled {
	}
}

.etr-blog-main {
	//padding-top: 0;
	.etr-blog-hero {
		@include elements.hero-wrapper-with-net;
	}
	.posts-list {
		@include wraps.etr-wrap-page;
		color: colors.$primary_fore;
	}
}
.etr-blog-hero {
	padding-bottom: (spacers.$spacer-sm * 1);
	@include bp.min(md) {
		padding-bottom: spacers.$spacer;
	}
}
.etr-blog-featured-header {
	height: 100%;
	//margin-bottom: 3.1rem;
	padding-top: spacers.$spacer;
	border-bottom: 1px solid colors.$gray_2;
}
.etr-blog-grid {
	margin-bottom: spacers.$spacer-sm;
	@include bp.min(md) {
		//grid-row-gap: spacers.$spacer;
	}
}
//
.etr-blog-categories-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: spacers.$spacer-sm;
	padding-left: 0;
	margin-bottom: 2rem;

	li {
		position: relative;
		margin-right: 1.5rem;
		padding-left: 0;
		font-size: ttype.$small-font-size;
		&::before {
			right: -1.05rem;
			left: initial;
			top: 0.55rem;
			width: 6px;
			height: 6px;
			@include bp.min(md) {
				top: 0.5rem;
				right: -1rem;
			}
		}
		&:last-child {
			&::before {
				display: none;
			}
		}
		//a {
		//  color: colors.$primary_emph;
		//  &:hover {
		//    color: colors.$primary_highlight;
		//  }
		//}
	}
}
