// settings

@use '../../variables/spacers' as s;
@use '../../mixins/breakpoints' as bp;

$modal-footer-height: 11rem;

//
.etr-modal--page {
  padding-right: 0 !important;
  .modal-dialog {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0 auto;
    .modal-content {
      max-height: 100%;
    }
    .modal-body {
      padding-top: 0;//1.15rem;
    }
  }
  .etr-modal-footer--fixed {
    @include bp.min(md) {
      position: fixed;
      bottom: 0;
      //left: calc(100vw - );
      height: $modal-footer-height;
      //max-width: calc(100% - #{s.$spacer * 4});
      //width: 40rem;
    }
  }
}
.etr-modal-body--with-fixed-footer {
  @include bp.min(md) {
    margin-bottom: $modal-footer-height;
  }
}