// settings

@use '../variables/colors' as c;
@use '../variables/spacers' as s;
@use '../base/general' as gtype;
@use '../base/typography' as ttype;

// @counter-style dash {
//   system: fixed;
// }

//
.ez-toc-widget-container, #ez-toc-container {
  .ez-toc-list {
    margin-bottom: 0;
    ul {
      margin-bottom: 0;
      padding-top: 0;
    }
    &.ez-toc-list-level-1 {
      padding-left: 0;
    }
    li {
      &::before {
        display: none;
      }
      &.active {
        & > a {
          font-weight: 500;
        }
      }
      a {
        margin-bottom: .25rem;
        font-size: .764rem;
        font-weight: 300;
        color: c.$primary_emph;
        &:hover {
          color: c.$primary_highlight;
        }
        &::before {
          //content: counters(dash, '') ' ' !important;
        }
      }
      ul {
        padding-left: 0; //.75rem;
      }
    }
  }
  .ez-toc-title {
    @include ttype.h6;
    //margin: 0 0 2rem 0;
    padding-bottom: 1.5rem !important;
    font-weight: 300 !important;
    font-size: ttype.$h6-font-size !important; //$p-font-size !important;
    //color: $black !important;
  }
}
.etr-entry-content {
  #ez-toc-container {
    padding-top: (s.$spacer);
    padding-left: 0;
    border: 0 none;
    box-shadow: none;
    .ez-toc-list {
      margin-bottom: 0;
      overflow: visible;
      li {
        //margin-bottom: .5rem;
        a {
          margin-bottom: .5rem;
          font-size: ttype.$small-font-size;
          font-weight: 400;
          &:hover {
            text-decoration: none;
          }
        }
        ul {
          margin-left: 0;
        }
      }
    }
  }
}
