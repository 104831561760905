// settings

@use '../variables/spacers' as s;
@use '../variables/colors' as c;
@use '../variables/text_types' as tt;
@use '../base/general' as gtype;
@use '../base/typography' as ttype;
@use '../mixins/breakpoints' as bp;

//
.etr-item {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: 0;
	padding: s.$spacer-m;
	border-radius: s.$spacer-m;
	background-color: c.$transparent_light;
	@include bp.min(lg) {
		min-height: 15rem;
		//margin-bottom: s.$spacer;
	}

	&:hover {
		figure {
			opacity: 0.95;
		} // a small change on the whole card
	}

	small {
		font-size: tt.$xs-font-size;
	}

	.etr-item-image {
		margin: 0;
	}

	.etr-entry-readingTime {
		margin-top: 0.2rem;

		small {
			display: flex;
			align-items: center;
			//color: c.$primary_emph;
		}
		.etr-icon-time {
			display: inline-block;
			margin-right: 0.25rem;
			background: c.$white;
		}
	}

	// featured item
	&.etr-item--featured {
		flex-direction: row;
		position: relative;

		// &:before {
		// 	content: '';
		// 	position: absolute;
		// 	width: 12rem;
		// 	height: 12rem;
		// 	left: -100vw;
		// 	background-image: url(./assets/images/robot-hand.png);
		// 	background-repeat: no-repeat;
		// 	background-size: contain;
		// 	transform: scaleX(-1) rotate(70deg);
		// 	z-index: 1;
		// }

		.new-post-label {
			position: absolute;
			top: -2rem;
			left: 0;
			display: block;
			margin-bottom: 1rem;
			font-weight: bold;
		}

		.etr-item-image {
			@include bp.min(xl) {
				margin-bottom: 0;
			}
			a {
				@include bp.min(md) {
					min-height: 17rem;
				}
				@include bp.min(xl) {
					min-height: 18rem;
				}
			}
		}
		.etr-item-content {
			display: flex;
			flex-direction: column;
			@include bp.min(xl) {
				height: 100%;
				padding: 0 s.$spacer-m;
			}
		}

		.etr-item-title {
			margin: 0;
		}

		.etr-item-excerpt {
			p {
				margin-top: 0.3rem;
			}
		}

		.etr-entry-meta {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
	// search item
	&.etr-item--search {
		min-height: initial;
		padding-bottom: s.$spacer-sm;
	}
}

.etr-item-content {
	padding: (s.$spacer-sm / 3) 0; //s.$spacer-sm;
}
.etr-item-excerpt p {
	//font-size: .885rem;
	margin-bottom: 0;
}
.etr-item-title {
	@include bp.max(sm) {
		@include ttype.h4;
	}
	margin: 1rem 0 0;
	line-height: 1.3;
}

//
.etr-item-image {
	margin-bottom: 1.55rem;
	a {
		position: relative;
		display: block;
		min-height: 12rem;
		overflow: hidden;
		@include bp.min(lg) {
			min-height: 14rem;
		}
		img {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			object-fit: cover;
			object-position: center;
			height: 100%;
		}
	}
}
.etr-item--featured-image {
	max-width: 100%;
}
