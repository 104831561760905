// settings

@use '../variables/spacers' as s;
@use '../base/typography' as ttype;

// directives

.etr-breadcrumbs {
  padding-top: s.$spacer-sm / 2;
  font-size: ttype.$small-font-size;
}