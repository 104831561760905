// settings

@use '../../variables/colors' as colors;
@use '../../variables/spacers' as spacers;
@use '../../mixins/breakpoints' as bp;
@use '../../helpers/animations' as animation;

$form-width: 42rem;

@mixin consultation-form-wrap {
	display: flex;
	flex-direction: column;
	//height: 100%;
	width: $form-width;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 spacers.$spacer-sm;
}

.etr-form-consultation {
	@include consultation-form-wrap;

	h3 {
		font-weight: 400;
	}

	.nav-link.active {
		pointer-events: none;
	}

	.etr-consultation-form-section {
		margin-top: spacers.$spacer;
	}

	.etr-consultation-form-title {
		color: colors.$primary_emph;
		line-height: 1.2;
		margin-bottom: spacers.$spacer-sm * 1.5;
	}

	select.etr-form-field {
		padding: 0;
		color: colors.$primary_fore;
		background-color: colors.$primary_bg;
	}
	.calendly-container .loader {
		font-size: 2rem;
	}

	//button#consultation_form_prev {
	//  border: 1px solid colors.$back-soft !important;
	//}
	button#consultation_form_next.button-disabled {
		color: colors.$primary_fore !important;
		background-color: transparent !important;
		border: none;
		opacity: 0.5;
		//pointer-events: none;
		cursor: default;
		transition: color 0.3s ease-out;

		&.emphasize-error {
			color: colors.$error !important;
		}
	}

	.form-invalid-container {
		p {
			font-weight: 300;
			font-style: italic;
			//color: mix(red, colors.$fore-main, 10%);
			color: colors.$primary_emph;
		}

		&.emphasize-error p {
			animation: emphError 1s ease-in-out 2;
		}
	}

	.options-list {
		//position: relative;
		grid-row-gap: 0;
		.option {
			position: relative;
		}
	}
	.objective-description {
		position: absolute;
		top: 0;
		left: -21rem;
		width: 14.5rem;
		transform: all 0.15s linear 0s;
		@include bp.range($max: 94rem) {
			display: none !important;
		}
	}
}
//

//
.etr-consultation-form-footer {
	//display: flex;
	margin-top: auto;
	//padding-top: (spacers.$spacer / 3);
	//@include bp.min(md) {
	//  padding-top: spacers.$spacer;
	//}
	padding-top: 0;
}
.etr-consultation-form-progress {
	padding-top: spacers.$spacer-sm;
	.nav-link.active {
		color: colors.$primary_emph !important;
		border-color: colors.$primary_emph !important;
	}
}
.etr-consultation-form-buttons {
	display: flex;
	justify-content: space-between;
	padding-bottom: spacers.$spacer-sm;
	@include bp.min(md) {
		padding-top: 0;
	}
}
// in modal
.etr-modal--page {
	.etr-consultation-form-footer {
		&.etr-modal-footer--fixed {
			@include consultation-form-wrap;
			justify-content: flex-end;
			//max-width: calc(100% - #{s.$spacer-sm * 2});
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
			// our breakpoint must be slightly before the form width otherwise
			// buttons don't get properly displayed
			@include bp.range($min: $form-width - 2.5rem) {
				width: calc(100% - #{spacers.$spacer * 2});
				max-width: $form-width - 2.5rem;
			}
		}
		.etr-consultation-form-buttons {
			padding-bottom: 0; //.75rem;
		}
	}
}
.etr-modal--consultation {
	.modal-content {
		background-color: colors.$tertiary_bg;
	}
	.modal-body,
	.modal-header {
		background-color: colors.$primary_bg;
	}
	.close.etr-button {
		color: colors.$tertiary_fore;
		&:hover {
			color: colors.$tertiary_emph;
			background-color: transparent;
		}
	}
}
