.flex,
.etr-flex {
	display: flex;
}
.flex--column,
.etr-flex--column {
	flex-direction: column;
}
// // justify
// .etr-flex-justify--start {
//   justify-content: flex-start;
// }
.flex-justify--between,
.etr-flex-justify--between {
	justify-content: space-between;
}
.etr-flex-justify--end {
	justify-content: flex-end;
}
// align
// .etr-flex-align--start {
//   align-items: flex-start;
// }
.flex-align--end,
.etr-flex-align--end {
	align-items: flex-end;
}
.flex-align--center,
.etr-flex-align--center {
	align-items: center;
}
//
.etr-flex-grow--1 {
	flex-grow: 1;
}

.flex-center-vertical {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
