@use '../variables/colors' as colors;

.toggle-plus-icon {
	position: relative;
	width: 22px;
	height: 22px;

	&:before,
	&:after {
		content: '';
		position: absolute;
		background-color: colors.$primary_emph;
		transition: transform 0.25s ease-out;
	}

	/* Vertical line */
	&:before {
		top: 0;
		left: 50%;
		width: 4px;
		height: 100%;
		margin-left: -2px;
	}

	/* horizontal line */
	&:after {
		top: 50%;
		left: 0;
		width: 100%;
		height: 4px;
		margin-top: -2px;
	}
}

.active .toggle-plus-icon {
	&:before {
		background-color: colors.$secondary_emph;
		transform: rotate(90deg);
	}
	&:after {
		background-color: colors.$secondary_emph;
		transform: rotate(180deg);
	}
}
