// settings

@use '../variables/colors' as colors;
@use '../mixins/breakpoints' as bp;
//@use '../base/typography' as gtype;
@use '../base/typography' as ttype;
@use 'forms/general' as gform;

.etr-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: gform.$input-button-height;
	line-height: gform.$input-button-height;
	max-width: 100%;
	min-width: 4.6rem;
	padding: 0 0.85rem;
	border: 0 none;
	cursor: pointer;
	font-size: ttype.$small-font-size;
	//line-height: 1;
	border-radius: 0;
	white-space: nowrap;
	&:active {
		box-shadow: inset 5px 5px 18px -6px rgba(0, 0, 0, 0.25);
	}
	&:focus {
		outline: none;
	}
	&:disabled {
		color: colors.$tertiary_bg;
		border-color: transparent !important;
		background-color: colors.$tertiary_fore !important;
		cursor: default;
	}

	// Colors
	background-color: colors.$primary_emph;
	color: colors.$primary_bg;
	&:hover {
		color: colors.$primary_bg;
		text-decoration: none;
		background-color: colors.$primary_highlight;
	}

	&--inverse {
		background-color: colors.$tertiary_bg;
		color: colors.$tertiary_fore;
		&:hover {
			background-color: colors.$tertiary_emph;
		}
	}
}

.etr-button--text {
	height: auto;
	min-width: initial;
	padding: 0;
	font-weight: 500;
	border: 0 none;
	background-color: transparent;
	//line-height: 1.8;
	&:active {
		box-shadow: none;
	}
	&:hover {
		//text-decoration: underline;
	}
}
.etr-button--cta {
	//@include button-size--sm;
	height: gform.$input-button-height;
	//min-width: 7.25rem;
	padding: 0 0.85rem;
	font-weight: 600;

	//box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
	border: 1px solid colors.$fore-main;
	transition: all 0.15s ease-in 0s;
}

.etr-button--primary {
	background-color: colors.$primary_emph;
	color: colors.$primary_bg;
	font-size: ttype.$small-font-size;
	font-weight: 600;
	//letter-spacing: .025rem;
	&:hover {
		background-color: colors.$primary_highlight;
	}
}

.etr-button--ghost {
	font-weight: 600;
	//color: c.$blue-4;
	color: colors.$tertiary_emph;
	background-color: transparent;
	border: 1px solid colors.$primary_bg;
	&:hover {
		//background-color: c.$gray-1;
		background-color: rgba(colors.$back-light, 0.5);
		//border-color: rgba(c.$back-light, 0.5);
	}
}

.etr-button--sm {
	min-width: 3.5rem;
	height: gform.$input-button-height-sm;
}

.etr-button--lg {
	@include bp.min(lg) {
		height: gform.$input-button-height-lg;
		padding: 0 2rem;
		font-size: ttype.$p-font-size;
		font-weight: 600;
	}
}

.etr-button--lg-2 {
	padding: 2rem 2.5rem;
	font-size: ttype.$p-font-size;

	@include bp.min(lg) {
		padding: 2.2rem 3rem;
		font-size: ttype.$h3-font-size-sm;
	}
}

.etr-button--xl {
	padding: 2rem 3.5rem;
	font-size: ttype.$p-font-size;

	@include bp.min(lg) {
		padding: 2.5rem 4rem;
		font-size: ttype.$p-font-size-lg;
	}
}
