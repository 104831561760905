// This file defines the colors that we use throughout the site
// Whenever we specify a color we should use these
// This file groups our colors into three groups, primary, secondary and tertiary
// Whenever you style a block you'd normally use one of the three. Say you want to have a block in primary colors,
// then it would be appropraite to have $primary_bg as a background color, $primary_fore as a foreground color,
// and $primary_emph and $primary_highlight as alternative foreground colors (emphasized).
// This pattern can be followed for all 3 sets.

// our pallete of B&W, yellow, blue and gray
$black: #000;
$white: #fff;

$blue_dark: rgb(14, 30, 49);
$blue_light: rgb(55, 77, 118);

$yellow_dark: rgb(224, 174, 71);
$yellow_light: rgb(248, 211, 71);

$gray_light: rgb(237, 233, 229);
//$gray_dark: darken($gray_light, 10%);

$primary_bg: $blue_dark;
$primary_fore: $gray_light;
$primary_emph: $yellow_dark;
$primary_highlight: $yellow_light;

$secondary_bg: $gray_light;
$secondary_fore: $black;
$secondary_emph: $blue_dark;
$secondary_highlight: $blue_light;

$tertiary_bg: $blue_light;
$tertiary_fore: $gray_light;
$tertiary_emph: $white;
//$tertiary_highlight: notused;

$back-main: $tertiary_bg;
$back-other: $primary_bg;
$fore-other: $primary_highlight;
$fore-main: $primary_emph;
$back-soft: $primary_fore;
$fore-soft: $back-main;
$gray-1: $back-soft;
$back-dark: $back-other;
$back-light: $back-main;
$blue: $back-light;
$blue-1: $back-dark; // palette 4
$blue-1-light: $back-light; // palette 4
$blue-2: $back-dark; // palette 4
$blue-4: $back-dark; // palette 4
$orange: $fore-main; // palette 1/2/4
$orange-2: $fore-other; // palette 4
$gray-1: $gray-1; // palette 0
$gray-2: $gray-1; // palette 0
$gray-3: $gray-1; // palette 0
$gray-4: $gray-1; // palette 0
$gray-5: $gray-1; // palette 0
$gray-6: $gray-1; // palette 0
$gray-7: $gray-1; // palette 0

$error: #cd5c5c;
$transparent_light: rgba(white, 0.15);
