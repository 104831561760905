@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.woff2') format('woff2'),
       url('./assets/fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
       url('./assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.woff2') format('woff2'),
       url('./assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2'),
       url('./assets/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.woff2') format('woff2'),
       url('./assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraBold.woff2') format('woff2'),
       url('./assets/fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Black.woff2') format('woff2'),
       url('./assets/fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

