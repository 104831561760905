// settings

@use '../../variables/colors' as c;
@use '../../variables/spacers' as s;
@use '../../mixins/breakpoints' as bp;

// directives

.etr-cta-contact {
	position: relative;
	overflow: hidden;
	//@include wrap-padding;
	//background-color: rgba(c.$blue-4, 1);
	padding: 4.65rem 0 0 0;
	@include bp.min(md) {
		//padding: 4.65rem 0;
		padding-top: 4.65rem;
		padding-bottom: 4.65rem;
	}
	@include bp.min(lg) {
		padding-top: 6.2rem;
		padding-bottom: 6.2rem;
	}

	&:before {
		content: '';
		position: absolute;
		width: 9rem;
		height: 9rem;
		top: -2rem;
		right: -0.5rem;
		background-image: url(./assets/images/robot-hand.png);
		background-repeat: no-repeat;
		background-size: contain;
		transform: rotate(30deg);

		@include bp.min(lg) {
			width: 12rem;
			height: 12rem;
		}

		@media screen and (min-width: 2048px) {
			content: none;
			display: none;
		}
	}
}
.etr-cta-contact-action {
	padding: (s.$spacer-sm / 2) 0 4.65rem 0;
	@include bp.min(md) {
		align-self: center;
		padding: 0;
		text-align: right;
	}
}
