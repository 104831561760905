// settings

@use '../variables/colors' as colors;
@use '../mixins/breakpoints' as bp;
@use '../base/general' as gtype;
@use '../base/typography' as ttype;

//directives

.etr-page-footer {
  //background-color: rgba(c.$blue-4, 1);
  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
  }
}
.etr-page-footer-inner {
  padding: 1.55rem 0;
  border-top: 1px solid rgba(colors.$back-soft, 0.5);
  @include bp.min(md) {
    display: flex;
    justify-content: space-between;
    padding: 3.1rem 0;
  }
}
.etr-page-footer-nav {
  .menu {
    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-left: 0;
      list-style-type: none;
      @include bp.min(md) {
        flex-direction: row;
      }
      .page_item, .menu-item {
        margin: 0;
        margin-right: 2rem;
        padding: .25rem 0;
        @include bp.min(md) {
          padding: 0;
        }
        &::before {
          content: initial;
        }
      }
      a {
        color: colors.$gray-5;
        font-size: ttype.$small-font-size;
        &:hover {
          color: colors.$white;
          text-decoration: none;
        }
      }
    }
  }
}
.etr-footer-info {
  padding-top: 1.55rem;
  //color: c.$white;
  @include bp.min(md) {
    padding: 0;
    text-align: right;
  }
  .copyright {
    color: colors.$back-soft;
    //color: c.$gray-3;
  }
}
//.etr-footer-info-separator {
//  display: none;
//  @include bp.min(md) {
//    display: inline-block;
//  }
//}
//.etr-footer-privacy {
//  margin-bottom: .75rem;
//  @include bp.min(md) {
//    margin-bottom: 0;
//  }
//}