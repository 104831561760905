// settings

@use '../variables/spacers' as s;
@use '../variables/colors' as c;
@use '../mixins/breakpoints' as bp;
@use '../base/typography' as ttype;
@use '../layouts/wraps';
@use 'buttons' as *;

//
.etr-page--default {
  #cookie-notice {
    // bottom: 1.25rem;
    font-family: 'Inter', sans-serif;
    background: transparent !important;

    @include bp.range($min: 93rem) {
      //bottom: 1rem;
    }
    .cookie-notice-container {
      @include wraps.etr-wrap-page;
      position: relative;
      padding: .7rem s.$spacer-sm .5rem s.$spacer-sm;
      font-size: ttype.$small-font-size;
      text-align: left;
      background-color: c.$gray-2;
      @include bp.min(md) {
        padding: 1.2rem s.$spacer 1rem s.$spacer;
      }
      .cn-set-cookie {
        @extend .etr-button;
        @extend .etr-button--ghost;
        @extend .etr-button--sm;
        //margin-top: .5rem;
        margin-left: 0;
        font-weight: 500;
        font-size: ttype.$small-font-size;
        font-family: 'Inter', sans-serif;
        &:hover {
          color: inherit;
          box-shadow: none;
        }
        // @include bp.min(md) {
        //   margin-top: 0;
        //   margin-left: 1rem;
        // }
      }
      .cn-text-container {
        margin-right: 1rem;
        .cn-notice-text {
          //color: c.$black;
        }
      }
      .cn-close-icon {
        &::before, &::after {
          //background-color: c.$black;
        }
      }
    }
  }
}