// settings

@use '../variables/colors' as colors;
@use '../variables/spacers' as spacers;
@use '../mixins/breakpoints' as bp;
@use '../layouts/wraps' as wraps;
@use '../helpers/text' as text;
@use '../mixins/elements' as elements;
@use '../partials/card' as ecard;

@use '../base/typography' as ttype;

$about-page-horizontal-padding: 10vw;

// directives

.etr-page--about {
	//background-image: none;
}
//
.etr-about-main {
	//padding-top: 0;
	.hero {
		@include elements.hero-wrapper-with-net;
		.people-figure img {
			height: auto;
		}
		.focus-best {
		}
		.computers-do-rest {
		}
	}
	h2 {
		//color: colors.$fore-main !important;
	}
	.content {
		@include wraps.etr-wrap-page;
		color: colors.$primary_bg;
	}

	.etr-info-header {
		position: relative;
		max-width: 25rem;
		padding: spacers.$spacer-m;
		padding-left: 0;
		background-color: colors.$transparent_light;
		border-top-right-radius: spacers.$spacer-m;
		border-bottom-right-radius: spacers.$spacer-m;

		&:before {
			content: '';
			position: absolute;
			background-color: colors.$transparent_light;
			height: 100%;
			width: 100vw;
			left: -100vw;
			top: 0;
		}

		[class^='etr-icon'] {
			display: inline-block;
			width: 3rem;
			height: 3rem;
			background: colors.$primary_emph;
			margin-right: 1rem;
		}

		.title {
			display: inline-block;
			vertical-align: super;
			@include ttype.h4;
			margin: 0 0 spacers.$spacer-m;
			padding: 0;
			line-height: 1.3;

			@include bp.min(lg) {
				margin: 0 0 spacers.$spacer-m;
				padding: 0;
			}
		}

		p {
			margin: 0;
		}
	}

	// .vision,
	// .mission {
	// 	@include bp.min(xl) {
	// 		//margin-bottom: spacers.$spacer;
	// 		padding-left: $about-page-horizontal-padding / 4;
	// 	}
	// 	.quote-card {
	// 		// display: inline-block;
	// 		// padding: 3.1rem 4.65rem;
	// 		@extend .etr-card;
	// 		background-color: colors.$secondary_bg;
	// 		color: colors.$secondary_fore;
	// 		max-width: 45rem;
	// 		h2 {
	// 			@include ttype.h6;
	// 			margin-bottom: 0;
	// 			color: colors.$secondary_emph;
	// 		}

	// 		.quote-text {
	// 			@include ttype.h3;
	// 			color: colors.$secondary_emph;
	// 			font-weight: 300 !important; // etr-text--light
	// 			// .etr-card-text--last;
	// 			margin-bottom: 1.75rem;
	// 			@include bp.min(lg) {
	// 				margin-bottom: 2rem;
	// 			}
	// 		}
	// 	}
	// }
	//.mission .quote-card {
	//  background-color: colors.$gray_1;
	//}

	.pricing {
		//@include bp.min(xl) {
		//  padding-left: 10vw;
		//  padding-right: 10vw;
		//  //padding-right: $about-page-horizontal-padding;
		//}
	}
}
