// settings

@use '../variables/spacers' as s;
@use '../mixins/breakpoints' as bp;

//
.etr-card {
  display: inline-block;
  padding: (s.$spacer-sm / 3) (s.$spacer-sm * 1.5);
  @include bp.min(lg) {
    padding: (s.$spacer-sm ) s.$spacer (s.$spacer-sm * 2) s.$spacer;
  }
  &.etr-card--sm {
    max-width: 45rem;
  }
  &.etr-card--lg {
    max-width: 65rem;
  }
}
.etr-card-title {
  //margin-bottom: 1.5rem;
}
.etr-card-text--last {
  margin-bottom: 1.75rem;
  @include bp.min(lg) {
    margin-bottom: 2rem;
  }
}