[class^='etr-icon'] {
	display: block;
	width: 1rem;
	height: 1rem;
	-webkit-mask-repeat: no-repeat !important;
	mask-repeat: no-repeat !important;
	-webkit-mask-size: contain !important;
	mask-size: contain !important;
	background: black;
}

.etr-icon {
	&-plus {
		mask: url('./assets/images/plus.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-time {
		mask: url('./assets/images/icons/wall-clock.svg');
		font-size: 1em;
		width: 0.75rem;
		height: 0.75rem;
	}

	&-businessman {
		mask: url('./assets/images/icons/businessman.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-handshake {
		mask: url('./assets/images/icons/handshake.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-leader {
		mask: url('./assets/images/icons/leader.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-leadership {
		mask: url('./assets/images/icons/leadership.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-service {
		mask: url('./assets/images/icons/service.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-social-care {
		mask: url('./assets/images/icons/social-care.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}

	&-team {
		mask: url('./assets/images/icons/team.svg');
		font-size: 1.2em;
		width: 1.2rem;
		height: 1.2rem;
	}
}
