// settings

@use "sass:list";
@use "sass:map";
@use '../variables/breakpoints' as b;

$min-map: (
	'sm': b.$bp-sphone-horiz-min,
	'md': b.$bp-tablet-min,
	'lg': b.$bp-tablet-horiz-min,
	'xl': b.$bp-desktop-min,
);
$max-map: (
	'xs': b.$bp-sphone-max,
	'sm': b.$bp-sphone-horiz-max,
	'md': b.$bp-tablet-max,
	'lg': b.$bp-tablet-horiz-max,
);

// TODO improve mixins

@mixin min($point) {
	@if $point == sm {
		@media (min-width: b.$bp-sphone-horiz-min) {
			@content;
		}
	} @else if $point == md {
		@media (min-width: b.$bp-tablet-min) {
			@content;
		}
	} @else if $point == lg {
		@media (min-width: b.$bp-tablet-horiz-min) {
			@content;
		}
	} @else if $point == xl {
		@media (min-width: b.$bp-desktop-min) {
			@content;
		}
	} @else {
		@media (min-width: $point) {
			@content;
		}
	}
}

@mixin max($point) {
	@if $point == xs {
		@media (max-width: b.$bp-sphone-max) {
			@content;
		}
	} @else if $point == sm {
		@media (max-width: b.$bp-sphone-horiz-max) {
			@content;
		}
	} @else if $point == md {
		@media (max-width: b.$bp-tablet-max) {
			@content;
		}
	} @else if $point == lg {
		@media (max-width: b.$bp-tablet-horiz-max) {
			@content;
		}
	} @else {
		@media (max-width: $point) {
			@content;
		}
	}
}

@mixin range($min: null, $max: null) {
	$min-is-value: list.index(('sm', 'md' 'lg', 'xl'), $min) == null;
	$max-is-value: list.index(('xs', 'sm', 'md', 'lg'), $max) == null;
	@if ($max == null) {
		@if (list.index(('sm', 'md' 'lg', 'xl'), $min) == null) {
			@media screen and (min-width: $min) {
				@content;
			}
		} @else {
			@include bp-min($min) {
				@content;
			}
			//@debug "debug #{$min}";
		}
	} @else if ($min == null) {
		@if (list.index(('xs', 'sm', 'md', 'lg'), $max) == null) {
			@media screen and (max-width: $max) {
				@content;
			}
		} @else {
			@include bp-max($max) {
				@content;
			}
		}
	} @else {
		@if (
			(list.index(('sm', 'md' 'lg', 'xl'), $min) == null) and
				(list.index(('xs', 'sm', 'md', 'lg'), $max) == null)
		) {
			@media screen and (min-width: $min) and (max-width: $max) {
				@content;
			}
		} @else if (list.index(('sm', 'md' 'lg', 'xl'), $min) == null) {
			@media screen and (min-width: $min) and (max-width: map.get($max-map, $max)) {
				@content;
			}
		} @else if (list.index(('xs', 'sm', 'md', 'lg'), $max) == null) {
			@media screen and (min-width: map.get($min-map, $min)) and (max-width: $max) {
				@content;
			}
		} @else {
			@media screen and (min-width: map.get($min-map, $min) and (max-width: map.get($max-map, $max))) {
				@content;
			}
		}
	}
}
