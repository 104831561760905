// settings

@use '../variables/colors' as c;

// directives

.etr-socialShare {

}
.etr-socialShare-link {
  margin: 0 .62rem;
  color: c.$gray-4;
  &:hover {
    color: c.$blue-2;
  }
  &:first-child {
    margin-left: 0;
  }
}