@use '../variables/spacers' as spacers;
@use '../variables/colors' as colors;
@use '../mixins/breakpoints' as breakpoints;
@use '../variables/text_types' as *;
@use '../partials/header' as header;
@use '../layouts/wraps' as wraps;

@mixin full-width-element {
	@include wraps.etr-wrap-page;

	//padding-top: spacers.$spacer-sm;
	//padding-bottom: spacers.$spacer-sm;
	background-color: colors.$primary_bg;

	@include breakpoints.min(sm) {
		//padding-top: spacers.$spacer * 1.25;
		padding-top: spacers.$spacer;
		padding-bottom: spacers.$spacer;
	}
}

@mixin full-width-emphasized {
	@include full-width-element;
	color: colors.$primary_emph;
	margin: auto;
	text-align: center;
}

@mixin full-width-emphasized-alt {
	@include full-width-element;
	background-color: colors.$gray_light;
	color: colors.$secondary_emph;
	margin: auto;
	text-align: center;
}

@mixin hero-header {
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 1.5 * header.$header-height + spacers.$spacer-sm;
	padding-bottom: 1.5 * header.$header-height + spacers.$spacer-sm;

	@include breakpoints.min(sm) {
	}
	@include breakpoints.min(md) {
		//height: 15.5rem;
		//padding-top: (spacers.$spacer-sm * 2);
	}
	@include breakpoints.min(lg) {
		flex-direction: row;
	}
	@include breakpoints.range($min: wraps.$page-wrap) {
		//height: 50vh;
		//min-height: 20rem;
		//padding-top: 13vh;
	}
}

@mixin hero-wrapper-with-net {
	min-height: 65vh;
	height: 100%;
	display: flex;
	align-items: center;
	padding-bottom: spacers.$spacer-xl;
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: colors.$back-other url('./assets/images/yellownet.svg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		opacity: 0.8;
	}

	header {
		@include wraps.etr-wrap-page;
		@include hero-header;
		width: 100%;
		height: 100%;

		figure img {
			width: 150px;
			height: 150px;
		}

		.title {
			//150px - icon, 2rem - margin
			width: 100%;
			font-size: $title-large-font-size-xs;
			text-transform: uppercase;
			font-weight: 400;

			em {
				display: block;
				text-transform: initial;
			}
		}

		@include breakpoints.min(lg) {
			.title {
				//150px - icon, 2rem - margin
				width: calc(100% - 150px - 2rem);
				margin: 0;
			}
		}
	}
}

@mixin vertical-align-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
