// settings

@use '../variables/colors' as colors;
@use '../variables/spacers' as spacers;
@use '../mixins/breakpoints' as bp;

// directives

.etr-archive-main {
	//background: colors.$back-other url('./assets/images/hero-net.svg') no-repeat center;
	padding-top: spacers.$spacer-sm;
	@include bp.min(xl) {
		padding-top: spacers.$spacer;
	}
	.etr-grid--columns {
		@include bp.min(lg) {
			//column-gap: 4rem;
		}
		[class*='grid-column'] {
			&:nth-child(odd) {
				//border-right: 1px solid $gray_2;
			}
		}
	}
}
.etr-archive-header {
	//padding-top: 3.1rem;
	//padding-bottom: 3.1rem;
}
