// settins

@use 'mixins/breakpoints' as bp;

// directives

.calendly-widget, calendly-inline-widget {
  height: 1350px ;
  z-index: 10002;
  @include bp.min(md) {
    //height: 1325px !important;
  }
}