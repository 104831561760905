// settings

@use '../variables/colors' as c;
@use '../mixins/breakpoints' as bp;
@use '../base/typography' as ttype;

$pagination-icon-margin: 1rem;
$pagination-font-weight: 500;

// directives

.etr-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include bp.min(md) {
    //padding-right: 1rem;
  }
}
.etr-pagination-item {
  //width: 3rem;
  .page-numbers {
    margin: 0 .5rem;
    font-weight: $pagination-font-weight;
    font-size: ttype.$small-font-size;
  }
}
.etr-pagination-link {
  display: flex;
  align-items: center;
  font-weight: $pagination-font-weight;
  font-size: ttype.$small-font-size;

  i {
    font-size: 24px;
  }
  &.disabled {
    color: rgba(c.$back-soft, 0.2);
    pointer-events: none;
  }
  &.previous {
    i {
      margin-right: $pagination-icon-margin;
      &::before {
        transform: rotate(180deg);
      }
    }
  }
  &.next {
    i {
      margin-left: $pagination-icon-margin;
    }
    .text {
      text-align: right;
    }
  }
  .text {
    width: 4rem;
    @include bp.max(lg) {
      display: none;
    }
  }
}