// settings

@use '../variables/colors' as colors;
@use '../variables/spacers' as s;
@use '../variables/fonts' as fonts;
@use '../mixins/breakpoints' as bp;
@forward '../variables/text_types';
@use '../variables/text_types' as *;

// By default, we have h2 and h4 and h6 in our secondary color

@mixin h1 {
	//max-width: 72rem;
	margin-bottom: 2.25rem;
	padding-top: $heading-margin-top-sm;
	font-size: $h1-font-size-sm; //2.55rem;
	font-weight: 600;
	line-height: 1.285;
	@include bp.min(lg) {
		margin-bottom: 4.25rem;
		padding-top: $heading-margin-top;
		font-size: $h1-font-size;
	}
}

@mixin h2 {
	//max-width: 60rem;
	margin-bottom: 2rem;
	padding-top: $heading-margin-top-sm;
	font-size: $h2-font-size-sm; //2.085rem;
	font-weight: 500;
	line-height: 1.285;
	@include bp.min(lg) {
		margin-bottom: 3.25rem;
		padding-top: $heading-margin-top;
		font-size: $h2-font-size;
	}

	color: colors.$primary_emph;
}

@mixin h3 {
	//max-width: 48rem;
	margin-bottom: 1.85rem;
	padding-top: $heading-margin-top-sm;
	font-size: $h3-font-size-sm; //1.718rem;
	font-weight: $heading-font-weight;
	line-height: 1.385;
	//letter-spacing: -0.022rem;
	@include bp.min(lg) {
		max-width: 48rem;
		margin-bottom: 2.5rem;
		padding-top: $heading-margin-top;
		font-size: $h3-font-size;
	}
}

@mixin h4 {
	//max-width: 50rem;
	padding-top: $heading-margin-top-sm;
	margin-bottom: 1.75rem;
	font-size: $h4-font-size-sm; //1.37rem;
	font-weight: $heading-font-weight;
	line-height: 1.45;
	//letter-spacing: -0.019rem;
	@include bp.min(lg) {
		max-width: 48rem;
		margin-bottom: 2.25rem;
		padding-top: $heading-margin-top-sm;
		font-size: $h4-font-size;
	}

	color: colors.$primary_emph;
}

@mixin h5 {
	//max-width: 41rem;
	margin-bottom: 1.5rem;
	padding-top: $heading-margin-top-sm;
	font-size: $h5-font-size-sm;
	font-weight: $heading-font-weight;
	line-height: 1.5;
	//letter-spacing: -0.019rem;
	@include bp.min(lg) {
		max-width: 48rem;
		margin-bottom: 2rem;
		padding-top: $heading-margin-top;
		font-size: $h5-font-size;
		//line-height: 35px;
	}
}

@mixin h6 {
	//max-width: 41rem;
	margin-bottom: 1.5rem;
	padding-top: $heading-margin-top-sm;
	font-size: $h6-font-size-sm;
	font-weight: 300;
	font-family: 'Inter', sans-serif;
	line-height: 1.75;
	text-transform: uppercase;
	//letter-spacing: -0.017rem;
	@include bp.min(lg) {
		max-width: 46rem;
		margin-bottom: 2rem;
		padding-top: $heading-margin-top;
		font-size: $h6-font-size;
		//line-height: 28px;
	}

	color: $small_titles_color;
}

@mixin p {
	//max-width: 41rem;
	color: $small_titles_color;
	margin-bottom: 1.5rem;
	font-size: $p-font-size-sm;
	font-weight: 400;
	font-family: 'Inter', sans-serif;
	line-height: 1.8;
	//letter-spacing: -0.017rem;
	@include bp.min(lg) {
		//max-width: 46rem;
		margin-bottom: s.$spacer-sm * 1.5;
		//margin-right: s.$spacer-sm;
		font-size: $p-font-size;
		//line-height: 1.8rem;
	}
}

@mixin small {
	font-size: $small-font-size; //.875rem;
	line-height: 1.6;
	color: $small_titles_color;
}

h1,
.h1 {
	@include h1;
}
h2,
.h2 {
	@include h2;
}
h3,
.h3 {
	@include h3;
}
h4,
.h4 {
	@include h4;
}
h5,
.h5 {
	@include h5;
}
h6,
.h6 {
	@include h6;
}
p,
.p {
	@include p;
}
small,
.small {
	@include small;
}

//
h1,
h2,
h3,
h4,
h5,
h6,
p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
.etr-title--large,
.etr-title--medium {
	//margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif;
	a {
		display: inline;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
br,
p,
a,
small,
time,
b,
strong,
i:not(.material-icons),
blockquote {
	&::selection {
		color: colors.$primary_bg;
		background-color: colors.$secondary_bg;
		&.alt {
			color: colors.$secondary_bg;
			background-color: colors.$primary_bg;
		}
	}
}

//////////////////////////////////////////////////
///// LISTS
//////////////////////////////////////////////////
ul,
ol,
dl {
	margin: 0;
	margin-bottom: 2rem;
	padding: 0;
	padding-left: s.$spacer-sm * 2;
	font-family: 'Inter', sans-serif;
	font-size: 1rem;
	@include bp.min(lg) {
		font-size: 1.1rem;
	}
}
ul,
dl {
	list-style-type: none;
}
ol {
	padding-left: s.$spacer-sm * 1.5;
	li {
		padding-left: 0.5rem;
	}
}
li {
	margin-bottom: s.$spacer-sm / 2;
	font-family: 'Inter', sans-serif;
	font-size: $p-font-size-sm;
	font-weight: 400;
	line-height: 1.8;
	@include bp.min(lg) {
		position: relative;
		margin-bottom: s.$spacer-sm;
		padding-left: 0.62rem;
		font-size: $p-font-size;
		//line-height: 1.4;
	}
}

ul {
	li {
		&::before {
			content: '';
			position: absolute;
			left: -1rem;
			top: 0.8rem; //calc(50% - #{$list-style-type-size / 2});
			width: $list-style-type-size;
			height: $list-style-type-size;
			border-radius: 20px;

			background-color: colors.$primary_fore;
			border: 1px solid colors.$primary_fore;
			&.alt {
				background-color: colors.$secondary_fore;
				border: 1px solid colors.$secondary_fore;
			}
		}
	}
	ul {
		padding-top: s.$spacer-sm;
		// margin-bottom: s.$spacer-sm;
		li {
			&::before {
				border: 1px solid colors.$gray_4;
				background-color: colors.$white;
			}
			ul {
				li::before {
					border-radius: 0;
					border: 1px solid colors.$gray_3;
				}
			}
		}
	}
}

.etr-link--title {
	color: colors.$primary_emph;
	&:hover {
		color: colors.$primary_highlight;
		cursor: pointer;
		background-color: transparent;
	}
	&.alt {
		color: colors.$secondary_fore;
		&:hover {
			color: colors.$secondary_highlight;
		}
	}
}

a {
	@extend .etr-link--title;
	display: inline-block;
	opacity: 1;
	text-decoration: none;
	line-height: inherit;
}

blockquote {
	margin: 0 0 1.5rem 0;
	padding: 0.5rem 0 0.5rem 1.75rem;
	font-weight: 600;
	font-style: italic;
	border-left: 2px solid colors.$primary_emph;
	@include bp.min(lg) {
		margin-bottom: 1.75rem;
	}
	p {
		margin: 0;
		font-weight: 600;
		font-style: italic;
	}
	&.alt {
		border-left: 2px solid colors.$secondary_emph;
		color: colors.$secondary_fore;
	}
}

strong,
b {
	font-weight: 600;
}

em {
	color: colors.$fore-main;
	font-style: normal;
}

.etr-title--large {
	max-width: initial;
	margin-bottom: 2.5rem;
	font-size: $title-large-font-size-sm;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: -0.022rem;
	@include bp.min(lg) {
		font-size: $title-large-font-size; // 5rem;
		margin-bottom: 5rem;
		font-weight: 600;
	}
}

.content-heading,
.content-heading-alt {
	line-height: inherit;
	margin: 0;
	text-transform: uppercase;
	font-size: $h3-font-size-sm;

	em {
		display: block;
	}
}

.content-heading-alt {
	color: colors.$secondary_emph;
}

.subheading {
	max-width: 100%;
	margin-top: 0;
	margin-bottom: 4rem;
	text-align: center;
	color: colors.$white;
	line-height: 1.8;
}
